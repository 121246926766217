







































































import { BceFile, debounce } from '@bcase/core';
import { Module, Reference, ModuleMetadata } from '@bcase/module-editor';
import Vue from 'vue';
import { Component, Ref, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import { ModuleModule } from '../../store/modules/module-module';
import { StorageModule } from '../../store/modules/storage-module';

@Component
export default class EditorModule extends Vue {
  @Ref('module-editor')
  private editorEl!: HTMLBceModuleEditorElement;

  private editor = getModule(ModuleModule);
  private storage = getModule(StorageModule);

  public targetFeature = webpack.FEATURES.target || [];
  public update = debounce(this.editor.updateModule.bind(this.editor), 2000);

  public get params() {
    const [id, version] = this.$route.params.module.split('@');
    return { module: { id, version } };
  }

  public get module() {
    return this.editor.current;
  }

  public get name() {
    return this.module && this.module.name;
  }

  public get categories() {
    const categories = this.$FEATURES['module_category'];
    return (
      categories &&
      categories.length &&
      categories.map(c => ({
        value: c,
        label: this.$t('target-audience-' + c),
      }))
    );
  }

  public get target() {
    return this.module && this.module.category
      ? this.module.category.split('+')
      : [];
  }

  public set target(value: string[]) {
    this.editorEl.set('category', value.join('+'), { emit: true });
  }

  public get order() {
    return '' + (this.module && this.module.metadata.order);
  }

  public set order(value: string) {
    this.editorEl.set('metadata.order', parseInt(value, 10), { emit: true });
  }

  public get price() {
    return '' + (this.module && this.module.metadata.price);
  }

  public set price(value: string) {
    this.editorEl.set('metadata.price', parseInt(value, 10), { emit: true });
  }

  public get structure() {
    return (this.module && this.module.metadata.structure) || '';
  }

  public set structure(value: string) {
    this.editorEl.set('metadata.structure', value, { emit: true });
  }

  async mounted() {
    await Promise.all([
      this.editor.load(this.params),
      this.storage.bind(this.$route.params.module),
    ]);

    this.watchModule(this.module);
  }

  @Watch('module')
  public watchModule(value?: Module) {
    this.editorEl.categories = this.categories as any;
    this.editorEl.value = value || Module.empty();
  }

  @Watch('module.metadata.license')
  public watchMetadata(metadata?: ModuleMetadata['license']) {
    const license = this.$refs['option-license'] as HTMLBceSelectElement;
    if (license) license.value = metadata ? [...metadata] : [];
  }

  public async handleFile(file: BceFile) {
    const { id, version } = this.module!;
    const group = `${id}@${version}`;
    const ref = `module/${id}/${file.id}`;

    await this.storage.upload({ ref, group, file });
  }

  public async handleFileDelete(id: string) {
    const group = `${this.module!.id}@${this.module!.version}`;
    await this.storage.delete({ id, group });
  }

  public navigate(ref: Reference) {
    this.$router.push(`${this.$route.path}/${ref.id}@${ref.version}`);
  }

  public async handleNext() {
    if (!this.module) return;

    // Navigate to existing start block
    if (this.module.flow.start.id) {
      const { id, version } = this.module.flow.start;
      this.$router.push(`${this.$route.path}/${id}@${version}`);
      return;
    }

    // Create start block
    const block = await this.editor.createBlock();
    if (!block) return;

    // Update module flow
    const { id, version } = block;
    const next = this.$firebase.generateId();

    this.editor.updateFlow({
      ...this.module.flow,
      blocks: {
        ...this.module.flow.blocks,
        // [id]: [{ id: next, version: 'v1' }]
      },
      start: { id, version },
    });

    // Navigate to newly created start block
    this.navigate({ id, version });
  }
}
